import {memo} from 'react'

import {Link} from '@ui/Modules'

import {FaInstagram} from 'react-icons/fa'
import {LiaLinkedin} from 'react-icons/lia'

interface Props {
	className?: string
}

const Footer = ({className}: Props) => {
	const current_year = new Date().getFullYear().toString()

	return (
		<footer className={className}>
			<div className="tw-w-full">
				<meta itemProp="copyrightYear" content={current_year} />
				<meta itemProp="copyrightHolder" content={process.env.NEXT_PUBLIC_APP_NAME} />

				<div className="section-container">
					<div className="sub-container">
						<div className="tw-grid tw-min-h-[300px] tw-w-full tw-grid-cols-1 tw-gap-8 tw-overflow-hidden tw-border-t lg:tw-grid-cols-5 lg:tw-gap-4 lg:tw-border-none">
							<div className="lg:tw-col-span-2">
								<img className="tw-w-40" src="/assets/images/icons/header_logo.svg" alt="company logo" />
								<div className="tw-text-lg lg:tw-w-4/5">
									<p>
										Everclime acknowledges the Australian Aboriginal and Torres Strait Islander peoples as the first inhabitants of the nation and the traditional custodians of the
										lands where we live, learn and work. We pay our respects to Elders past, present and emerging.
									</p>
								</div>
							</div>
							<div className="tw-flex tw-flex-col tw-gap-2">
								<div className="lg:tw-mb-4">
									<h4 className="tw-text-2xl">Legal</h4>
								</div>
								<div>
									<a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=243f8400-7ec7-455b-89d3-d2d5a2a3612a">
										<span className="tw-text-lg hover:tw-underline">Privacy Policy</span>
									</a>
								</div>

								<div>
									<a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7745904b-936f-440e-9544-0088e9f4571e">
										<span className="tw-text-lg hover:tw-underline">Terms of Service</span>
									</a>
								</div>
							</div>
							<div className="tw-flex tw-flex-col tw-gap-2">
								<div className="lg:tw-mb-4">
									<h4 className="tw-text-2xl">General</h4>
								</div>
								<div>
									<Link href="/certificates">
										<span className="tw-text-lg hover:tw-underline">Cards</span>
									</Link>
								</div>

								<div>
									<Link href="/projects">
										<span className="tw-text-lg hover:tw-underline">Projects</span>
									</Link>
								</div>
								<div>
									<Link href="/brands">
										<span className="tw-text-lg hover:tw-underline">Brands</span>
									</Link>
								</div>
								<div>
									<Link href="/registries">
										<span className="tw-text-lg hover:tw-underline">Registries</span>
									</Link>
								</div>
							</div>
							<div className="tw-flex tw-flex-col tw-gap-2">
								<div className="lg:tw-mb-4">
									<h4 className="tw-text-2xl">Contact</h4>
								</div>
								<div>
									<span className="tw-text-lg">E-Mail: info@everclime.com</span>
								</div>
								<div>
									<span className="tw-text-lg">Perth | Sydney | London</span>
								</div>
							</div>
						</div>
						<hr className="tw-mb-4"></hr>
						<div className="tw-flex tw-justify-between">
							<div>
								<span>© 2024 everclime.com. All rights reserved</span>
							</div>
							<div className="tw-flex tw-gap-2">
								<a className="tw-flex tw-cursor-pointer tw-items-center" href="https://www.instagram.com/everclime/" target="_blank" rel="noreferrer">
									<FaInstagram className="tw-mr-2 tw-h-[30px] tw-w-[30px] hover:tw-scale-105" />
								</a>
								<a className="tw-flex tw-cursor-pointer tw-items-center" href="https://www.linkedin.com/company/everclime/?originalSubdomain=au" target="_blank" rel="noreferrer">
									<LiaLinkedin className="tw-mr-2 tw-h-[30px] tw-w-[30px] hover:tw-scale-105" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default memo(Footer)
