import Blob from './blob'
import {useRouter} from 'next/router'
import Link from 'next/link'
import Image from 'next/image'

const targetLink = 'https://www.everclime.com/brands/everclime/impactaction/6a88671d-5373-4b3e-b70e-3564bcbe30de/'

export default function Banner() {
	const router = useRouter()

	const handleCardClick = () => {
		window.open(targetLink, '_blank').focus()
	}

	return (
		<div className="section-container tw-relative !tw-mb-0 tw-overflow-hidden tw-pt-0 lg:tw-h-5/6 xl:!tw-max-h-[1200px]">
			{/* Dotted Background */}
			<div className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-bg-white tw-bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"></div>
			{/* Banner */}
			<div className="sub-container tw-z-10">
				<div className="tw-grid tw-h-full tw-grid-cols-1 tw-gap-10 lg:tw-grid-cols-2">
					<div className="tw-flex tw-items-center tw-justify-center">
						<div>
							<h1 className="tw-text-5xl tw-font-black lg:tw-text-6xl">
								Connecting Consumers, Brands & Impact via <span className="tw-text-primary">Impact Cards</span>
							</h1>
							<p className="!tw-mt-4 tw-text-lg lg:tw-text-xl">
								Choose meaningful projects, integrate with ticketing & e-commerce, add rewards to engage your audience - all while making a real-world impact.
							</p>
							<div className="tw-mt-8 tw-hidden tw-flex-row tw-gap-4 md:tw-flex">
								<Link href={targetLink} className="tw-cursor-pointer">
									<div className="button-primary tw-cursor-pointer">Claim your Free Impact Card</div>
								</Link>
								<a className="button-primary-outlined" href="https://forms.monday.com/forms/859afadebefbd8b4968340c00e1c7e02?r=apse2" target="_blank" rel="noreferrer">
									I am a brand
								</a>
							</div>
						</div>
					</div>
					<div className="tw-relative tw-flex tw-items-center tw-justify-center">
						<div className="tw-absolute tw-inset-0 tw-top-[70px] tw-left-1/2 tw-w-[300px] tw--translate-x-1/2 lg:tw-top-1/2 lg:tw-w-[600px] lg:-tw-translate-y-[60%]">
							<Blob />
						</div>

						<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center [perspective:800px]">
							<div className=" tw-transition tw-duration-500 [transform:rotateX(-7deg)_rotateY(-17deg)_rotateZ(3deg)] hover:[transform:rotateX(0deg)_rotateY(0deg)_rotateZ(0deg)]">
								<div
									className="tw-aspect-[1/1.67] tw-w-[200px] tw-animate-[hoverFlipAnimation_15s_infinite_ease-in-out] tw-cursor-pointer lg:tw-w-[300px]"
									style={{position: 'relative'}}
								>
									<Image src={'/assets/images/landingpage/banner/impact-card.png'} alt="everclime club impact card" layout="fill" onClick={() => handleCardClick()} priority />
								</div>
							</div>
							{/* Shadow */}
							<div className="tw-z-10 -tw-ml-5 tw-hidden [transform:rotateX(-7deg)_rotateY(-17deg)_rotateZ(3deg)] lg:tw-block">
								<div
									className="tw-h-[30px] tw-w-[200px] tw-animate-[shadowAnimation_15s_infinite_ease-in-out]  tw-rounded-3xl tw-bg-[rgba(0,0,0,0.7)] tw-blur-xl lg:tw-w-[300px]
"
								/>
							</div>
						</div>
					</div>
					<div className="tw-mt-8 tw-flex tw-flex-col tw-gap-4 md:tw-hidden">
						<Link href="https://everclime.com/brands/everclime/impactaction/6a88671d-5373-4b3e-b70e-3564bcbe30de/" className="tw-cursor-pointer">
							<div className="button-primary tw-cursor-pointer">Claim your Free Impact Card</div>
						</Link>
						<a className="button-primary-outlined" href="https://forms.monday.com/forms/859afadebefbd8b4968340c00e1c7e02?r=apse2" target="_blank" rel="noreferrer">
							I am a brand
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}
