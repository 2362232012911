import React, { ReactElement } from "react";

interface Props {
  children: ReactElement[];
  style?: object;
}

export default function Slider(props: Props) {
  return (
    <div
      className="tw-relative tw-w-full tw-overflow-hidden tw-py-5"
      style={{ ...props.style }}
    >
      <div
        className="animate-scroll tw-flex tw-w-max tw-items-center tw-gap-6"
        style={{
          animation: "scroll 30s linear infinite",
          animationPlayState: "running",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.animationPlayState = "paused")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.animationPlayState = "running")
        }
      >
        {props.children}
      </div>

      {/* Tailwind animation keyframe */}
      <style>
        {`
          @keyframes scroll {
            0% { transform: translateX(0); }
            100% { transform: translateX(-50%); }
          }
        `}
      </style>
    </div>
  );
}
