'use client'

import ProjectCard from './ProjectCard'
import {useVisibility} from '@helpers/scrollHelper'
import Slider from '@ui/blocks/Slider/page'
interface ProjectPreview {
	id: string
	tag: string
	title: string
	linkUrl: string
}

const projects: ProjectPreview[] = [
	{
		id: 'yuin-station',
		tag: 'earth',
		title: 'Yuin Station - WA HIR Carbon Project',
		linkUrl: 'https://everclime.com/projects/yuin-station---murchison-hir-aggregation/',
	},
	{
		id: 'forever-reef',
		tag: 'water',
		title: 'Forever Reef: Living Coral Biobank',
		linkUrl: 'https://everclime.com/projects/forever-reef-living-coral-biobank/',
	},
	{
		id: 'forever-wild',
		tag: 'land',
		title: 'Forever Wild: Western Deserts',
		linkUrl: 'https://everclime.com/projects/forever-wild-western-deserts/',
	},
	{
		id: 'carbon-positive-australia',
		tag: 'earth',
		title: 'Carbon Positive Australia: Warralakin',
		linkUrl: 'https://everclime.com/projects/carbon-positive-australia-warralakin/',
	},
	{
		id: 'airseed',
		tag: 'land',
		title: 'AirSeed: Cattai Wetlands',
		linkUrl: 'https://everclime.com/projects/airseed-cattai-wetlands/',
	},
]
const baseAssetsUrl = '/assets/images/landingpage/projects/'

export default function Projects() {
	const sliderProjects = [...projects, ...projects]

	const [isVisible, scrollElement, scrollPosition] = useVisibility<HTMLDivElement>()

	const calculateTranslate = (start: number, end: number, offset = 300) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset

		const factor = 0.005

		if (start < 0) {
			return Math.min(end, start * ((elementEndPosition - scrollPosition) * factor))
		} else {
			return Math.max(end, start * ((elementEndPosition - scrollPosition) * factor))
		}
	}

	const calculateOpacity = (offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset
		return Math.min(1, scrollPosition / elementEndPosition)
	}

	return (
		<div className="section-container tw-flex-col tw-items-center !tw-px-0">
			<div className="section-container !tw-mb-0 tw-overflow-hidden !tw-py-0" ref={scrollElement}>
				<div className="sub-container">
					<h2
						className="!tw-mt-0 lg:tw-w-2/3"
						style={{
							transform: `translateX(${calculateTranslate(((1 + 1) % 2 ? 1 : -1) * ((1 + 1) * 0.2) * -200, 0)}px)`,
							opacity: `${calculateOpacity(0)}`,
							transition: 'transform opacity 0.1s',
						}}
					>
						Discover Impact Projects supported by the brands <span className="tw-text-primary">you love</span>
					</h2>
				</div>
			</div>

			<Slider
				style={{
					transform: `translateY(${calculateTranslate(((1 + 1) % 2 ? 1 : -1) * ((1 + 1) * 0.2) * -200, 0)}px)`,
					opacity: `${calculateOpacity()}`,
					transition: 'transform opacity 0.1s',
				}}
			>
				{sliderProjects.map((item, index) => (
					<div key={index} className="tw-aspect-[1.5/2] tw-w-[200px] tw-overflow-hidden tw-rounded-xl tw-transition tw-duration-300 hover:tw-scale-105 hover:tw-shadow lg:tw-w-[300px]">
						<ProjectCard logoSrc={`${baseAssetsUrl}/logo-${item.id}.png`} {...item} imageSrc={`${baseAssetsUrl}/bg-${item.id}.png`} />
					</div>
				))}
			</Slider>
		</div>
	)
}
