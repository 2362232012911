'use client'

import {useVisibility} from '@helpers/scrollHelper'
import Image from 'next/image'

const testimonials = [
	// {
	// 	logoSrc: '/assets/images/landingpage/testimonials/unisport-logo.png',
	// 	quote: 'Everclime enabled us to engage with our +5000 students to demonstrate & fund tangible impact outcomes, promoting a very strong message for what we stand for.',
	// 	profileSrc: '/assets/images/landingpage/unisport-ceo-profile.png',
	// 	profileName: 'Mark Sinderberry',
	// 	position: 'CEO - UniSport Australia',
	// },
	// {
	// 	logoSrc: '/assets/images/landingpage/testimonials/unisport-logo.png',
	// 	quote: 'One of the most rewarding aspects of this project was how actively our opinions were valued and integrated. Being involved in shaping impactful initiatives like the ACT Wildlife Centre and Forever Reef projects was an incredible experience, and I feel proud of the difference we’ve made.',
	// 	profileSrc: '/assets/images/landingpage/unisport-student-profile.png',
	// 	profileName: 'UniSport Student',
	// 	position: 'Student',
	// },
	// {
	// 	logoSrc: '/assets/images/landingpage/testimonials/ims-logo.png',
	// 	quote: 'IMS Impact Profile on Everclime was highly valuable in our recent tender submissions making our Environmental impact much more transparent.',
	// 	profileSrc: '/assets/images/landingpage/ims-profile.png',
	// 	profileName: 'Sustainability Manager',
	// 	position: 'International Maritime Services',
	// },
	{
		logoSrc: '/assets/images/landingpage/testimonials/hockey-australia-logo.png',
		quote: "Working with Everclime to define a transparent and comprehensive view of our carbon impact has helped Hockey Australia take a proactive step towards sustainability. Everclime's understanding of the sports sector ensured a seamless integration of carbon management into our organisational goals.",
		profileSrc: '',
		profileName: 'Melissa Gmeiner',
		position: 'Social Impact & Sustainability Manager',
		brand: 'Hockey Australia',
	},
	// {
	// 	logoSrc: '/assets/images/landingpage/testimonials/jetwave-logo.png',
	// 	quote: 'Jetwave is grateful for the opportunity to work with Everclime to offset our emissions and redirect funds away from international impact and invest in a local kelp project.',
	// 	profileSrc: '/assets/images/landingpage/jetwave-profile.png',
	// 	profileName: 'Leigh Burns',
	// 	position: 'HSEQ Manager - Jetwave Marine',
	// },
	// {
	// 	logoSrc: '/assets/images/landingpage/testimonials/cockburn-logo.png',
	// 	quote: 'Everclime helped reduce our overall investment cost by 35% compared with last year, while also contributing to local Australian initiatives.',
	// 	profileSrc: '/assets/images/landingpage/cockburn-profile.png',
	// 	profileName: 'Julie Christensen',
	// 	position: 'Sustainability Manager - Cockburn City Council',
	// },
	// {
	// 	logoSrc: '/assets/images/landingpage/testimonials/water-polo-logo.png',
	// 	quote: "The Everclime platform made it easy for our attendees to make a positive environmental impact, and the digital Impact Cards meant all was impact was transparent and engaging. Everclime's innovative approach enhanced our event, and we look forward to future partnerships.",
	// 	profileSrc: '/assets/images/landingpage/water-polo-profile.png',
	// 	profileName: 'Water Polo Australia',
	// 	position: 'Water Polo Australia',
	// },
]

export default function Testimonials() {
	const [isVisible, scrollElement, scrollPosition] = useVisibility<HTMLDivElement>()

	const calculateTranslate = (start: number, end: number, offset = 350) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset

		const factor = 0.005

		if (start < 0) {
			return Math.min(end, start * ((elementEndPosition - scrollPosition) * factor))
		} else {
			return Math.max(end, start * ((elementEndPosition - scrollPosition) * factor))
		}
	}

	const calculateOpacity = (offset = 800) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset
		const opacity = (elementEndPosition - scrollPosition) * -0.01
		return Math.min(1, opacity)
	}

	return (
		<div className="section-container">
			<div className="sub-container tw-relative tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-rounded-xl md:tw-justify-between" ref={scrollElement}>
				{/* Dotted Background */}
				<div className="tw-absolute -tw-left-[5rem] tw-top-[2rem] tw-hidden tw-aspect-square tw-w-1/4 lg:tw-block">
					<div className="tw-relative tw-h-full tw-w-full ">
						<div
							className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-bg-white tw-bg-[radial-gradient(#ced1d6_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"
							style={{
								transform: `translateY(${calculateTranslate(1 * 200, 0)}px)`,
								opacity: `${calculateOpacity()}`,
								transition: 'transform opacity 0.1s',
							}}
						></div>
					</div>
				</div>
				{/* Dotted Background */}

				<div className="tw-absolute tw-right-[1rem] -tw-bottom-[4rem] tw-hidden tw-aspect-square tw-w-1/3 lg:tw-block">
					<div className="tw-relative tw-h-full tw-w-full ">
						<div
							className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-bg-white tw-bg-[radial-gradient(#ced1d6_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"
							style={{
								transform: `translateX(${calculateTranslate(1 * 200, 0)}px)`,
								opacity: `${calculateOpacity()}`,
								transition: 'transform opacity 0.1s',
							}}
						></div>
					</div>
				</div>

				{/* Content */}
				<div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-text-center">
					{/* Company Logo */}
					<div
						className="tw-flex tw-justify-center"
						style={{
							transform: `translateY(${calculateTranslate(-200, 0)}px)`,
							opacity: `${calculateOpacity()}`,
							transition: 'transform opacity 0.1s',
						}}
					>
						<Image src={testimonials[0].logoSrc} alt="logo of featured partner" width={50} height={50} className="tw-rounded-full tw-object-fill" unoptimized />
					</div>
					<div
						className="tw-mt-2"
						style={{
							transform: `translateY(${calculateTranslate(-200, 0)}px)`,
							opacity: `${calculateOpacity()}`,
							transition: 'transform opacity 0.1s',
						}}
					>
						<span className="tw-font-semibold">{testimonials[0].brand}</span>
					</div>
					{/* Quote */}
					<h2
						className="tw-w-full tw-text-lg !tw-font-medium lg:tw-w-2/3 lg:tw-text-3xl"
						style={{
							transform: `translateX(${calculateTranslate(200, 0)}px)`,
							opacity: `${calculateOpacity()}`,
							transition: 'transform opacity 0.1s',
						}}
					>
						&quot;{testimonials[0].quote}&quot;
					</h2>
					{/* Spokesperson */}
					<div
						className="tw-flex tw-flex-col tw-items-center"
						style={{
							transform: `translateY(${calculateTranslate(200, 0)}px)`,
							opacity: `${calculateOpacity()}`,
							transition: 'transform opacity 0.1s',
						}}
					>
						<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-2 md:tw-flex-row">
							{testimonials[0].profileSrc ? (
								<Image src={testimonials[0].profileSrc} alt="logo of featured partner" width={50} height={50} className="tw-rounded-full tw-object-cover" unoptimized />
							) : (
								<div className="tw-flex tw-aspect-square tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-black tw-object-cover tw-text-white lg:tw-h-10 lg:tw-w-10">
									{testimonials[0].profileName.substring(0, 1)}
								</div>
							)}

							<div className="tw-flex tw-flex-col tw-text-center lg:tw-text-start">
								<span className="tw-font-bold">{testimonials[0].profileName}</span>
								<span>{testimonials[0].position}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
