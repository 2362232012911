import {FaDroplet} from 'react-icons/fa6'
import {FaGlobeAmericas} from 'react-icons/fa'
import {FaLeaf} from 'react-icons/fa'
import {FaHandHoldingHeart} from 'react-icons/fa6'
import Image from 'next/image'

interface ProjectPreview {
	id: string
	tag: string
	title: string
	logoSrc: string
	imageSrc: string
	linkUrl: string
}

const tagColors = {
	water: 'tw-bg-blue-200 tw-text-blue-800',
	land: 'tw-bg-green-200 tw-text-green-800',
	earth: 'tw-bg-yellow-200 tw-text-yellow-800',
	humanity: 'tw-bg-pink-200 tw-text-pink-800',
}

const tagIcons = {
	water: <FaDroplet />,
	land: <FaLeaf />,
	earth: <FaGlobeAmericas />,
	humanity: <FaHandHoldingHeart />,
}

export default function ProjectCard(props: ProjectPreview) {
	const titleStyles: React.CSSProperties = {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		display: 'block',
	}

	// Detect if `-webkit-line-clamp` is supported
	// const supportsLineClamp = CSS.supports && CSS.supports('-webkit-line-clamp', '2')

	// if (supportsLineClamp) {
	Object.assign(titleStyles, {
		whiteSpace: 'initial',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
	})
	// }

	return (
		<a href={props.linkUrl} target="_blank" rel="noopener noreferrer" className="tw-h-full tw-w-full tw-flex-none hover:tw-text-primary">
			<div className="tw-relative tw-h-full tw-w-full tw-shadow-md">
				<div
					className="tw-relative tw-aspect-square tw-w-full"
					style={{
						backgroundImage: `url(${props.imageSrc})`,
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}
				></div>
				{/* Project Details */}
				<div className="tw-absolute tw-bottom-0 tw-w-full">
					<div className="tw-flex tw-max-h-[120px] tw-min-h-[120px] tw-w-full tw-items-center tw-justify-between tw-bg-white tw-p-4">
						<img src={props.logoSrc} alt="logo" className="tw-mr-2 tw-hidden tw-aspect-square tw-h-20 tw-w-20 tw-shrink-0 tw-grow-0 tw-rounded-full tw-border lg:tw-flex" />
						<div className="tw-flex-1">
							<div className="tw-group tw-relative tw-flex">
								<p style={titleStyles} className="tw-max-h-[50px] tw-max-w-full tw-text-base tw-font-semibold">
									{props.title}
								</p>
								<span className="tw-absolute -tw-top-[45px] tw-rounded-md tw-bg-gray-200 tw-p-1 tw-px-1 tw-text-sm tw-text-gray-800 tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100">
									{props.title}
								</span>
							</div>
							<div className="tw-mt-2 tw-flex">
								<img src={props.logoSrc} alt="logo" className="tw-mr-2 tw-flex tw-aspect-square tw-h-6 tw-w-6 tw-shrink-0 tw-grow-0 tw-rounded-full tw-border lg:tw-hidden" />
								<div className={`${tagColors[props.tag]} tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded tw-px-3 tw-py-1 tw-text-xs tw-font-bold`}>
									{tagIcons[props.tag]} {props.tag.charAt(0).toUpperCase() + props.tag.slice(1)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a>
	)
}
