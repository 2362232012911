import Image from 'next/image'
import Link from 'next/link'
import {useVisibility} from '@helpers/scrollHelper'

export default function Brand() {
	const [isVisible, scrollElement, scrollPosition, screenWidth] = useVisibility<HTMLDivElement>()

	const calculateTranslate = (start: number, end: number, offset = 400) => {
		offset = offset + screenWidth / 10
		const elementEndPosition = scrollElement?.current?.offsetTop - offset

		const factor = 0.005

		if (start < 0) {
			return Math.min(end, start * ((elementEndPosition - scrollPosition) * factor))
		} else {
			return Math.max(end, start * ((elementEndPosition - scrollPosition) * factor))
		}
	}

	const calculateOpacity = (offset = 400) => {
		offset = offset + screenWidth / 10
		const elementEndPosition = scrollElement?.current?.offsetTop - offset
		return Math.min(1, scrollPosition / elementEndPosition)
	}

	return (
		<div className="section-container">
			<div className="sub-container tw-relative tw-overflow-hidden tw-rounded-xl tw-bg-gray-800 tw-p-5 lg:tw-p-10" ref={scrollElement}>
				{/* Background Image Pattern */}
				<Image
					src="/assets/images/landingpage/collection/circles-and-squares.svg"
					layout="fill"
					className="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover tw-opacity-40"
					alt="pattern background image"
				/>
				<div className="tw-z-10 tw-grid tw-h-full tw-grid-cols-1 lg:tw-grid-cols-3">
					<div className="tw-col-span-2 tw-flex tw-items-center tw-justify-center">
						<div>
							<h2
								className="tw-mt-0 tw-text-white"
								style={{
									transform: `translateX(${calculateTranslate(-200, 0)}px)`,
									opacity: `${calculateOpacity()}`,
									transition: 'transform opacity 0.1s',
								}}
							>
								Are you a <span className="tw-text-primary">brand</span>?
							</h2>
							<p className="tw-text-lg tw-text-white lg:tw-text-2xl">
								Join leading brands in creating impact cards that drive & track real impact. Showcase your sustainability efforts and engage your audience in a meaningful way.
							</p>
							<div className="tw-mt-8 tw-flex tw-flex-col tw-gap-4 md:tw-flex-row">
								<a className="button-primary" href="https://forms.monday.com/forms/859afadebefbd8b4968340c00e1c7e02?r=apse2" target="_blank" rel="noreferrer">
									Book a demo
								</a>
								<a
									className="button-primary-outlined !tw-border-white !tw-text-white hover:!tw-border-primary hover:!tw-text-primary"
									href="https://forms.monday.com/forms/859afadebefbd8b4968340c00e1c7e02?r=apse2"
									target="_blank"
									rel="noreferrer"
								>
									Learn More
								</a>
							</div>
						</div>
					</div>
					<div className="tw-relative tw-flex tw-items-center tw-justify-center">
						<Image
							src="/assets/images/landingpage/brand/brand.gif"
							alt=""
							width={400}
							height={400}
							unoptimized
							style={{
								transform: `translateY(${calculateTranslate(200, 0)}px) translateX(${calculateTranslate(200, 0)}px)`,
								opacity: `${calculateOpacity()}`,
								transition: 'transform opacity 0.1s',
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
