import Head from 'next/head'
import Header from '@chunks/_app/Header'
import Footer from '@chunks/_app/Footer'
import Banner from './root/Banner/page'
import Partners from './root/Partners/page'
import Explore from './root/Explore/page'
import Unlock from './root/Unlock/page'
import Collection from './root/Collection/page'
import Brand from './root/Brand/page'
import Projects from './root/Projects/page'
import Testimonials from './root/Testimonials/page'

const HomePage = ({
	description = 'Fuelled by rich content and a gamified experience, Everclime allows audiences to fund impact action themselves, or engage with brands to unlock funding through its innovative platform accelerating Impact action.',
}) => {
	return (
		<>
			<Head>
				<title>{process.env.NEXT_PUBLIC_APP_NAME}</title>

				<meta property="og:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
				<meta name="twitter:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
				<meta name="DC.Title" content={process.env.NEXT_PUBLIC_APP_NAME} />

				<meta name="description" content={description} />
				<meta name="DC.Description" content={description} />
				<meta name="twitter:description" content={description} />
				<meta property="og:description" content={description} />
			</Head>

			<Header />

			<Banner />
			<Partners />
			<Explore />
			<Unlock />
			<Testimonials />
			<Collection />
			<Projects />
			<Brand />

			<Footer className="tw-z-10" />
		</>
	)
}

export default HomePage
