'use client'

import Image from 'next/image'
import styles from './Unlock.module.css'

interface Props {
	id: number
	title: string
	subtitle: string
	imageSrc: string
}

export default function Step(props: Props) {
	return (
		<div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-between tw-transition-transform tw-duration-700 lg:tw-flex-row">
			<div className={`relative tw-relative tw-flex tw-flex-col tw-justify-end tw-bg-white tw-p-8 lg:tw-flex-1 lg:tw-p-10`}>
				<Image
					src="/assets/images/landingpage/unlock/waves.svg"
					className="tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-h-full tw-w-full tw-object-cover tw-opacity-20"
					layout="fill"
					objectFit="cover"
					alt="pattern background"
					unoptimized
				/>
				<div className="tw-z-20 tw-mb-2 tw-flex tw-items-center">
					<div className="tw-z-20 tw-mr-3 tw-flex tw-max-h-[35px] tw-min-h-[35px] tw-min-w-[35px] tw-max-w-[35px] tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-800 tw-text-2xl tw-font-black tw-text-white">
						{props.id}
					</div>
					<p className="tw-z-20 tw-text-xl tw-font-bold lg:tw-text-2xl">{props.title}</p>
				</div>
				<p className="tw-z-20 tw-overflow-scroll tw-text-lg lg:tw-text-xl">{props.subtitle}</p>
			</div>
			<div className="tw-flex tw-flex-1 tw-items-end tw-justify-center tw-bg-primary/60">
				<div className="tw-relative tw-aspect-[1.5/2] tw-h-[340px] tw-duration-[800ms] lg:tw-h-[500px]">
					<Image src={props.imageSrc} layout="fill" objectFit="fill" alt="Picture of the card step" unoptimized />
				</div>
			</div>
		</div>
	)
}
