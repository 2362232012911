import {useRouter} from 'next/router'
import {memo} from 'react'

import {useAtomValue} from 'jotai'
import {UserState} from '@states/User'
import {NotificationsState} from '@states/Notifications'

import {Link, Icon, Menu, ModalButton} from '@ui/Modules'

import UserMenu from '@chunks/_app/header/UserMenu'
import HeaderMenu from '@chunks/_app/header/HeaderMenu'
import path from 'path'

const Header = ({
	menu = [
		{
			title: 'Cards',
			slug: '/certificates',
		},
		{
			title: 'Brands',
			slug: '/brands',
		},
		{
			title: 'Projects',
			slug: '/projects',
		},
	],
	className = '',
}) => {
	const user = useAtomValue(UserState)
	const notifications = useAtomValue(NotificationsState)

	const router = useRouter()

	return (
		<header className="section-container tw-top-0 tw-z-50 !tw-mt-0 !tw-mb-0 !tw-py-0">
			<div className={'sub-container navigation tw-flex-row tw-items-center tw-justify-between !tw-bg-slate-50 tw-py-4' + (className ? ' ' + className : '')}>
				<Link href="/">
					<div style={{color: 'inherit'}}>
						<img src="/assets/images/icons/header_logo.svg" alt="" />
					</div>
				</Link>
				<nav className="tw-flex tw-select-none tw-flex-row tw-items-center tw-gap-x-6">
					<div className="tw-hidden md:tw-flex">
						<ul itemScope itemType="http://schema.org/SiteNavigationElement" className="tw-flex tw-items-center tw-gap-x-[32px]">
							{menu.map((item) => (
								<li key={item['title']}>
									{item['submenu'] && (
										<Menu
											items={item['submenu']}
											className="ui-menu-primary ui-link ui-link-primary tw-flex tw-items-center tw-gap-x-[10px] tw-text-lg"
											menuClassName="ui-arrow ui-arrow-secondary ui-arrow-top"
										>
											{item['title']}

											<Icon className="tw-h-[12px] tw-w-[12px]" name="#app-icon-chevron-down" />
										</Menu>
									)}

									{!item['submenu'] && (
										<>
											{item['slug'] && (
												<a href={item['slug']} itemProp="url" className={`tw-text-lg ${router.pathname == item['slug'] ? 'tw-font-bold tw-text-primary' : ''}`} tabIndex={1}>
													{item['title']}
												</a>
											)}
											{item['link'] && (
												<a href={item['link']} target="_blank" rel="noopener noreferrer nofollow" tabIndex={1} className="tw-text-lg">
													{item['title']}
												</a>
											)}
										</>
									)}
								</li>
							))}
						</ul>
					</div>

					{/* TODO: comment back in, once site is done */}
					{/* <button className="ui-button ui-button-primary tw-hidden tw-max-h-[37.5px] tw-bg-primary tw-px-[18.5px] tw-text-[18px] tw-capitalize tw-text-white tw-transition hover:tw-scale-105 hover:tw-bg-primary sm:tw-flex">
					Claim a Card
				</button> */}

					{/* TODO: comment back in, once site is done */}
					{/* <button className="ui-button ui-button-primary tw-hidden tw-max-h-[37.5px] tw-border-primary tw-px-[18.5px] tw-text-[18px] tw-capitalize tw-text-primary tw-transition hover:tw-scale-105 sm:tw-flex">
					For Brands
				</button> */}

					<div className="tw-flex tw-items-center tw-gap-x-[32px]">
						{!user && (
							<>
								<ModalButton
									style={{
										boxShadow: '0px -4px 1.9px 0px rgba(0, 0, 0, 0.02) inset, 0px 2px 5.3px 0px rgba(0, 0, 0, 0.05)',
									}}
									modalTarget="login"
									className="ui-button ui-button-primary tw-hidden tw-max-h-[37.5px] tw-px-[18.5px] tw-text-[18px] tw-transition hover:tw-scale-105 sm:tw-flex"
								>
									Sign in
								</ModalButton>
								<ModalButton modalTarget="login" className="tw-block sm:tw-hidden">
									<Icon className="tw-h-[24px] tw-w-[24px]" name="#app-icon-user" />
								</ModalButton>
							</>
						)}
						{user && <UserMenu />}
						<div className="md:tw-hidden">
							<HeaderMenu />
						</div>
					</div>
				</nav>
			</div>
		</header>
	)
}

export default memo(Header)
