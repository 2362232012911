'use client'

import Step from './Step'
import {Autoplay, Pagination} from 'swiper/modules'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Swiper as SwiperClass} from 'swiper/types'
import 'swiper/css'
import 'swiper/css/pagination'
import styles from './Unlock.module.css'
import {useVisibility} from '@helpers/scrollHelper'
import {useEffect, useRef, useState} from 'react'

const items = [
	{
		id: 1,
		title: 'Click, Scan or Swipe',
		subtitle: 'Interact with an impact card to claim the rewards. Impact cards can integrate with ticketing, memberships, social media campaigns or any consumer e-commerce transaction',
		imageName: 'unlock-1.png',
	},
	{
		id: 2,
		title: 'Claim you Card',
		subtitle: 'Users can claim their cards by storing them into their personal profiles on the Everclime Platform.',
		imageName: 'unlock-2.png',
	},
	{
		id: 3,
		title: 'Redeem your Rewards',
		subtitle: 'Users can access rewards, discounts or competitions attached to the impact cards they engage with.',
		imageName: 'unlock-3.png',
	},
	{
		id: 4,
		title: 'Expand your Collection',
		subtitle: "Users can collect their impact cards tracking their impact and continuing to follow the progress of the projects they've supported.",
		imageName: 'unlock-4.png',
	},
	{
		id: 5,
		title: 'Closing the Loop',
		subtitle: 'Everclime communicates project updates and progress back to the consumer. Providing evidence and transparency of the impact outcome.',
		imageName: 'unlock-5.png',
	},
]

export default function Unlock() {
	const swiperRef = useRef(null)
	const [swiper, setSwiper] = useState<SwiperClass | null>(null)
	const progressCircle = useRef(null)
	const progressContent = useRef(null)
	const onAutoplayTimeLeft = (s, time, progress) => {
		progressCircle.current.style.setProperty('--progress', 1 - progress)
		progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
	}

	const [isVisible, scrollElement, scrollPosition] = useVisibility<HTMLDivElement>()

	const imageSrc = (imageName: string) => {
		return `/assets/images/landingpage/unlock/unlock-previews/${imageName}`
	}

	useEffect(() => {
		if (swiperRef.current) {
			if (isVisible) {
				swiperRef.current?.swiper.autoplay.start()
			} else {
				swiperRef.current?.swiper.autoplay.stop()
			}
		}
	}, [isVisible, swiper])

	const handleMouseEnter = () => {
		if (swiperRef.current) swiperRef.current?.swiper.autoplay.start()
	}
	const handleMouseLeave = () => {
		if (swiperRef.current) swiperRef.current?.swiper.autoplay.stop()
	}

	const calculateTranslate = (start: number, end: number, offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset

		const factor = 0.005

		if (start < 0) {
			return Math.min(end, start * ((elementEndPosition - scrollPosition) * factor))
		} else {
			return Math.max(end, start * ((elementEndPosition - scrollPosition) * factor))
		}
	}

	const calculateOpacity = (offset = 250) => {
		const elementEndPosition = scrollElement?.current?.offsetTop - offset
		return Math.min(1, scrollPosition / elementEndPosition)
	}

	return (
		<div className="section-container">
			<div className="sub-container" ref={scrollElement}>
				<h2 className="!tw-mt-0">
					The <span className="tw-text-primary">Impact Card </span>
					User Experience
				</h2>
				<div
					className="tw-h-[550px] tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-xl tw-bg-transparent lg:tw-h-[600px]"
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					style={{
						transform: `translateX(${calculateTranslate(1 * 200, 0)}px)`,
						opacity: `${calculateOpacity()}`,
						transition: 'transform opacity 0.1s',
					}}
				>
					<Swiper
						ref={swiperRef}
						direction={'vertical'}
						slidesPerView={1}
						speed={2000}
						loop={true}
						centeredSlides={true}
						pagination={{
							clickable: true,
							renderBullet: (index, className) => {
								return `<span class="${className} ${styles.paginationBullet}"></span>`
							},
						}}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
						}}
						modules={[Autoplay, Pagination]}
						onAutoplayTimeLeft={onAutoplayTimeLeft}
						className="tw-h-full"
						onSwiper={(swiper) => {
							setSwiper(swiper)
						}}
					>
						{items.map((item) => (
							<SwiperSlide key={item.id}>
								<Step {...item} imageSrc={imageSrc(item.imageName)} />
							</SwiperSlide>
						))}
						<div className={`${styles.autoplayProgress}`} slot="container-end" style={{color: 'white'}}>
							<svg viewBox="0 0 48 48" ref={progressCircle} style={{stroke: 'white'}}>
								<circle cx="24" cy="24" r="20"></circle>
							</svg>
							<span ref={progressContent}></span>
						</div>
					</Swiper>
				</div>
			</div>
		</div>
	)
}
