import Link from 'next/link'
import React from 'react'
import Image from 'next/image'

export default function Partners() {
	const baseAssetsUrl = '/assets/images/landingpage/partner-logos/'
	const partners = [
		{imageName: 'hockey-australia.png', linkUrl: 'https://everclime.com/brands/hockey-australia/'},
		{imageName: 'busselton-jetty.png', linkUrl: 'https://everclime.com/brands/busselton-jetty/'},
		{imageName: 'nexus.png', linkUrl: 'https://everclime.com/brands/nexus-airlines/'},
		{imageName: 'dad.png', linkUrl: 'https://everclime.com/brands/d-and-ad/'},
		{imageName: 'unisport.png', linkUrl: 'https://everclime.com/brands/unisport-australia'},
		{imageName: 'antarctica-flights.png', linkUrl: 'https://everclime.com/brands/antartica-flights/'},
		{imageName: 'soccerex.png', linkUrl: 'https://everclime.com/brands/soccerex/'},
		{imageName: 'arena.png', linkUrl: 'https://everclime.com/brands/arena-group/'},
	]

	const combinedUrl = (logoName: string) => {
		return `${baseAssetsUrl}${logoName}`
	}

	return (
		<div className="section-container">
			<div className="sub-container">
				<div className="tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-gap-2 lg:tw-justify-between">
					{partners.map((partner) => (
						<Link href={partner.linkUrl} key={partner.imageName}>
							<div>
								<Image
									src={`${baseAssetsUrl}${partner.imageName}`}
									alt="partner company logo"
									width={100}
									height={80}
									className="tw-cursor-pointer tw-object-contain tw-transition tw-duration-300 hover:tw-scale-105"
									unoptimized
								/>
							</div>
						</Link>
					))}
				</div>
			</div>
		</div>
	)
}
